// import React from 'react';
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import Cookies from "universal-cookie";

const PrivateRoute = ({ children }) => {
  const cookies = new Cookies();
  const dashBoardToken = cookies.get("access_token");

  const isValidUser = Boolean(dashBoardToken);

  return isValidUser ? children : <Navigate to="/login" />;
};

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PrivateRoute;
