import React, { useContext, useEffect, useRef, useState } from "react";
import { AppBar, Box, IconButton, Toolbar } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import preloader2 from "../../assets/img/preloader-3.gif";
import "./Dashboard.scss";
import Logout from "./Logout";
import SideBarComponent from "./SideBarComponent";
import MainContext from "../../context/MainContext";
import HorizontalLineLoader from "../../json/horizontal-line-loader.json";
import NotificationBlock from "../notifications/NotificationBlock";
import Lottie from "lottie-web";

const Dashboard = ({ children }) => {
  //setSigned, setMountLogin

  const { isInitPreLoading, isPreLoading } = useContext(MainContext);

  const Preloader = () => {
    return (
      <div className="preloader-section">
        <img src={preloader2} alt="Loading..." />
      </div>
    );
  };

  const PreloaderAnimation = () => {
    const containerRef = useRef(null);

    useEffect(() => {
      const anim = Lottie.loadAnimation({
        container: containerRef.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: HorizontalLineLoader,
      });

      anim.setSpeed(0.8);

      return () => {
        anim.destroy();
      };
    }, []);

    return <div ref={containerRef} className="preloader-lottie-animation" />;
  };

  const [collapsed, setCollapsed] = useState();

  /* Local Storage */

  useEffect(() => {
    const storedValue = localStorage.getItem("isCollapsedNav");
    const isCollapsedNav =
      !!storedValue && storedValue.toLowerCase() === "true";
    setCollapsed(isCollapsedNav);
  }, []);

  /*  */

  const [toggled, setToggled] = useState(false);
  const [broken, setBroken] = useState(false);
  /*  */

  return (
    <div>
      {isPreLoading && <PreloaderAnimation />}

      {isInitPreLoading ? (
        <Preloader />
      ) : (
        <>
          <div>
            <div style={{ display: "flex", height: "100%" }}>
              <SideBarComponent
                collapsed={collapsed}
                toggled={toggled}
                setToggled={setToggled}
                broken={broken}
                setBroken={setBroken}
              />
              <main
                style={{ padding: 10 }}
                className={`main-component-section p-0 ${
                  collapsed ? "sidebar-close" : "sidebar-open"
                }`}
              >
                <Box className="top-navbar">
                  <AppBar position="static">
                    <Toolbar className="d-flex justify-content-between">
                      <div>
                        <IconButton
                          size="large"
                          edge="start"
                          color="inherit"
                          aria-label="menu"
                          className="collapse-button-desktop"
                          onClick={() => {
                            setCollapsed(!collapsed);
                            localStorage.setItem("isCollapsedNav", !collapsed);
                          }}
                        >
                          <FontAwesomeIcon icon={faBars} />
                        </IconButton>
                        {broken && (
                          <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            className="collapse-button-mobile"
                            onClick={() => {
                              setCollapsed(false);
                              setToggled(!toggled);
                            }}
                          >
                            <FontAwesomeIcon icon={faBars} />
                            <img
                              src={require("../../assets/img/dh-logo-light.png")}
                              alt=""
                              className="dashboard-icon mx-2"
                            />
                          </IconButton>
                        )}
                      </div>
                      <div className="d-flex justify-content-start">
                        <NotificationBlock />
                        <Logout
                        // setSigned={setSigned}
                        // setMountLogin={setMountLogin}
                        />
                      </div>
                    </Toolbar>
                  </AppBar>
                </Box>

                <section className="main-component">{children}</section>
              </main>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Dashboard;
